import React, { useState, useEffect } from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import HeroSection from "../components/Sections/Hero";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Header from "../components/Sections/Header";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer";
import styled from "styled-components";


export default function Landing() {
  const [showScrollTop, setShowScrollTop] = useState(false);

  // Show button when user scrolls down 300px
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <TopNavbar />
      <HeroSection />
      <Services />
      <Projects />
      <Header />
      <Contact />
      <Footer />

      {/* Scroll to Top Button */}
      {showScrollTop && (
        <ScrollTopButton onClick={scrollToTop}>
          <span>&#8593;</span> {/* This is the up arrow symbol */}
        </ScrollTopButton>
      )}
    </>
  );
}


const ScrollTopButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease;

  &:hover {
    background-color: #333;
  }

  &:focus {
    outline: none;
  }

  span {
    margin: 0;
  }

  @media (max-width: 860px) {
    width: 45px;
    height: 45px;
    font-size: 20px;
  }
`;

export { ScrollTopButton };
