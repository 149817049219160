import React, { useState } from "react";
import styled from "styled-components";
// Assets
// import ContactImg1 from "../../assets/img/contact-1.png";
// import ContactImg2 from "../../assets/img/contact-2.png";
import PhoneIcon from "../../assets/svg/call.png";  // Assuming you have an image for the phone icon
import MailIcon from "../../assets/svg/mail.png";   // Assuming you have an image for the mail icon

export default function Contact() {
  const [result, setResult] = useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "a0053009-e64c-4bcf-bf26-1df61c852812");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <Wrapper id="contact">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Let's get in touch</h1>
            {/* <p className="font13">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua.
            </p> */}
          </HeaderInfo>
          <Row>
            <FormWrapper>
              <Form onSubmit={onSubmit}>
                <label className="font13">First name:</label>
                <input type="text" id="fname" name="fname" className="font20 extraBold" required />
                <label className="font13">Email:</label>
                <input type="email" id="email" name="email" className="font20 extraBold" required />
                <label className="font13">Subject:</label>
                <input type="text" id="subject" name="subject" className="font20 extraBold" required />
                <label className="font13">Message:</label>
                <textarea rows="4" cols="50" id="message" name="message" className="font20 extraBold" required />
                <button type="submit" className="pointer animate radius8">Submit</button>
              </Form>
              <span>{result}</span>
            </FormWrapper>
            <ContactInfoWrapper>
              <div>
                <ContactHeader>Contact us via:</ContactHeader>
                <ContactItem href="tel:+9916427965">
                  <IconWrapper>
                    <img src={PhoneIcon} alt="phone" className="icon" />
                  </IconWrapper>
                  <ContactText>9916427965</ContactText>
                </ContactItem>
                <ContactItem href="mailto:vishnuvijay@jugaadfilms.com">
                  <IconWrapper>
                    <img src={MailIcon} alt="mail" className="icon" />
                  </IconWrapper>
                  <ContactText>vishnuvijay@jugaadfilms.com</ContactText>
                </ContactItem>
              </div>
            </ContactInfoWrapper>
          </Row>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FormWrapper = styled.div`
  flex: 1;
  min-width: 300px;
  @media (max-width: 768px) {
    order: 2;
  }
`;

const ContactInfoWrapper = styled.div`
  flex: 1;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 768px) {
    order: 1;
    margin-bottom: 20px;
  }
`;

const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
  button {
    border: 1px solid ${(props) => (props.border ? "#707070" : "#232027")};
    background-color: ${(props) => (props.border ? "transparent" : "#232027")};
    width: autp;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    outline: none;
    color: ${(props) => (props.border ? "#707070" : "#fff")};
    font-family: 'Montserrat'; /* Apply the Google Font */
    font-size: 20px;
    :hover {
      background-color: ${(props) => (props.border ? "transparent" : "#3a393b")};
      border: 1px solid #7620ff;
      color: ${(props) => (props.border ? "#7620ff" : "#fff")};
    }
  }
`;

const ContactHeader = styled.h3`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    margin-left: 30vh;
  }
`;

const ContactItem = styled.a`
  display: flex;
  align-items: center;
  margin: 20px 0;
  color: inherit;
  text-decoration: none;
  font-size: 20px;
  @media (min-width: 768px) {
    margin-left: 30vh;
  }
  img.icon {
    margin-right: 15px;
    width: 30px;
    height: 30px;
  }
`;

const ContactText = styled.span`
  font-size: 20px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;
