import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
// import FullButton from "../Buttons/FullButton";
import ClientSlider from "../Elements/ClientSlider";

// Assets
import ProjectImg1 from "../../assets/img/Mention.png";
import ProjectImg2 from "../../assets/img/Facebook Ad.png";
import ProjectImg3 from "../../assets/img/Video Tutorial.png";
import ProjectImg4 from "../../assets/img/Content Creation.png";
import ProjectImg5 from "../../assets/img/Artificial Intelligence 2.png";
import ProjectImg6 from "../../assets/img/Music.png";
import '../../style/index.css';

export default function Projects() {
  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Services</h1>
            <p className="font13">
              
            </p>
          </HeaderInfo>
          <div className="row textLeft">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg1}
                title="Documentaries"
                text={
                  <>
                    <p>Short Documentaries: Compelling, concise storytelling for brands, causes, or events.</p>
                    <p>Behind-the-Scenes Documentaries: Immersive looks into the process of creating art, events, or campaigns.</p>
                  </>
                }
                // action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg2}
                title="Commercials & Advertising"
                text={
                  <>
                    <p>TV Commercials: High-quality, cinematic ads tailored to captivate audiences.</p>
                    <p>Digital Campaign Videos: Engaging content optimized for online platforms and social media.</p>
                    <p>Brand Stories: Building emotional connections through powerful narratives.</p>
                  </>
                }
                // action={() => alert("clicked")}
              />
            </div>
            <div className="cardss col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg3}
                title="Social Media Content"
                text={
                  <>
                    <p>Platform-Specific Content: Tailored visuals for Instagram, TikTok, YouTube, LinkedIn, and more.</p>
                    <p>Reels & Shorts Production: Quick, impactful videos that drive engagement.</p>
                    <p>Video Editing & Post-Production: Smooth transitions, eye-catching effects, and cohesive storytelling.</p>
                    <p>Audio & Music Design: Original compositions and soundtracks for content that resonates.</p>
                  </>
                }
                // action={() => alert("clicked")}
              />
            </div>
          </div>
          <div className="row textLeft">
            <div className="cardss col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg4}
                title="Corporate & Event Videos"
                text={
                  <>
                    <p>Corporate Films: Professional videos to showcase company values, achievements, and culture.</p>
                    <p>Event Highlights: Capturing the essence of conferences, weddings, launches, or galas.</p>
                    <p>Training & Educational Videos: Informative and engaging content for employees or audiences.</p>
                  </>
                }
                // action={() => alert("clicked")}
              />
            </div>
            <div className="cardss col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg5}
                title="AI-Driven Creations"
                text={
                  <>
                    <p>AI Video Production: Innovative AI-assisted filming and editing for precision and speed.</p>
                    <p>Automated AI Content: Scalable, AI-generated video solutions for recurring needs.</p>
                    <p>AI Animated Videos: Stunning animations powered by advanced AI technology.</p>
                    <p>AI-Powered Films: Combining creativity and artificial intelligence to produce unique cinematic experiences.</p>
                  </>
                }
                // action={() => alert("clicked")}
              />
            </div>
            <div className="cardss col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg6}
                title="Music Videos"
                text={
                  <>
                    <p>Creative Direction & Filming: Bringing your music to life with visually compelling concepts.</p>
                    <p>High-End Editing: Transforming raw footage into a polished masterpiece.</p>
                  </>
                }
                // action={() => alert("clicked")}
              />
            </div>
          </div>
          <div className="row flexCenter">

          </div>
        </div>
      </div>
      <div className="slidess">
        <h2>OUR CLIENTS</h2>
        <div className="lightBg" style={{ padding: "50px 0" }}>
          
          <div className="container">
            <ClientSlider />
          </div>
        </div>
      </div>        
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  h2{
    font-size: 1.5rem;
    font-weight: 500;
    position: absolute; /* Position the h2 element absolutely within the Wrapper */
    left: 50%;
    transform: translateX(-50%); /* Center the h2 element horizontally */
    font-family: Montserrat;
    margin: 20px auto;
    
  }
`;
const HeaderInfo = styled.div`
  text-align: left;
  @media (max-width: 860px) {
    text-align: center;
  }
`;


// const ProjectBox = styled.div`
//   margin-bottom: 50px;
//   padding: 30px;
//   background: #fff;
//   border-radius: 8px;
//   box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
//   text-align: left;
//   img {
//     width: 100%;
//     height: auto;
//     border-radius: 8px;
//   }
//   h3 {
//     font-size: 20px;
//     font-weight: 700;
//     margin-top: 15px;
//   }
//   p {
//     font-size: 14px;
//     margin-top: 10px;
//   }
// `;
