import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";

export default function Contact() {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <Wrapper>
      <div className="container">
        <InnerWrapper>
          {/* "Jugaad FILMS" left aligned */}
          <Link className="animate pointer" to="home" smooth={true} offset={-80}>
            <h1 className="font15 extraBold whiteColor" style={{ marginLeft: "5px" }}>
              Jugaad FILMS
            </h1>
          </Link>

          {/* Footer Content centered */}
          <FooterContentWrapper>
            <StyleP className="whiteColor font13">
              © {getCurrentYear()} - <span className="greyColor font13">Jugaad FILMS</span> All Rights Reserved
            </StyleP>
            <StyledLink
              href="https://www.linkedin.com/in/vishnu-sm-348b9024a/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Designed by <HighlightText>Vishnu SM</HighlightText>
            </StyledLink>
          </FooterContentWrapper>
        </InnerWrapper>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  background-color: #000; /* Black background */
  color: #fff; /* White text color */
  padding: 10px 0; /* Reduced padding to make footer shorter */
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto; /* Centering the content */
  
  @media (max-width: 550px) {
    flex-direction: column; /* Stack vertically on mobile */
    align-items: center; /* Center everything on mobile */
  }
`;

const FooterContentWrapper = styled.div`
  text-align: center; /* Center text inside the wrapper */
  width: 100%; /* Full width to ensure it is centered */
  margin-top: 10px; /* Adjust margin */
  
  @media (max-width: 550px) {
    margin-top: 5px; /* Reduce margin on mobile */
  }
`;

const StyleP = styled.p`
  margin: 0;
  font-size: 13px;
  @media (max-width: 550px) {
    margin: 5px 0; /* Adjust spacing on mobile */
  }
`;

const StyledLink = styled.a`
  display: inline-block;
  margin-top: 5px;
  font-size: 13px;
  color: #fff;
  text-decoration: none;

  &:hover {
    color: #fff; /* No hover color on the "Designed by" text */
    text-decoration: none;
  }
`;

const HighlightText = styled.span`
  font-weight: bold;
  color: grey; /* Highlight color for "Vishnu SM" */
  
  &:hover {
    color: #244961; /* Change color on hover */
    text-decoration: none; /* No underline on hover */
  }
`;
