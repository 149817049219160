import React from 'react';
import styled, { keyframes } from 'styled-components';
import '../../../src/style/index.css';
import Logo from "../../assets/img/logo.png"; // Import the logo image

const shine = keyframes`
  0% {
    background-position: 0;
  }
  60% {
    background-position: 600px;
  }
  100% {
    background-position: 900px;
  }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const HeroSection = () => {
  return (
    <Wrapper id="home">
      <Content>
          <LogoImage src={Logo} alt="Jugaad Films Logo" /> {/* Adjust the path and styling as needed */}
          <Subtitle>Telling stories in every Frame</Subtitle>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: white;
  color: white;
`;

const LogoImage = styled.img`
  margin-left: auto;
  margin-right: auto;
  height: 30rem;
  width: auto;
  max-width: 100%;
  animation: ${fadeIn} 3s ease-in-out;
`;

const Subtitle = styled.h2`
  font-size: clamp(1.5rem, 4vw, 2rem); /* Responsive font size */
  padding: 64px;
  color: black;
  background: linear-gradient(to right, hsl(0, 0%, 0%) 0%, hsl(0, 0%, 100%) 10%, hsl(0, 0%, 0%) 20%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${shine} 8s infinite linear;

  /* Media Queries for further adjustments */
  @media (max-width: 768px) {
    font-size: clamp(1.25rem, 6vw, 1.75rem);
  }

  @media (max-width: 480px) {
    font-size: clamp(1rem, 8vw, 1.25rem);
  }
`;

const Content = styled.div`
  text-align: center;
  animation: ${fadeIn} 5s ease-in-out;
`;

export default HeroSection;
