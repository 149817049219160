import React from "react";
import styled from "styled-components";

// Import Google Font
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
`;

export default function FullButton({ title, action, border }) {
  return (
    <>
      <GlobalStyle />
      <Wrapper
        className="animate pointer radius8"
        onClick={()=>window.location.href='https://www.youtube.com/watch?si=sVQUwg81B12Qfo9f&v=QKR_e7b5Vkk&feature=youtu.be'}
        border={border}
      >
        {title}
      </Wrapper>
    </>
  );
}

const Wrapper = styled.button`
  border: 1px solid ${(props) => (props.border ? "#707070" : "#232027")};
  background-color: ${(props) => (props.border ? "transparent" : "#232027")};
  width: 90%;
  padding: 10px;
  outline: none;
  color: ${(props) => (props.border ? "#707070" : "#fff")};
  font-family: 'Montserrat'; /* Apply the Google Font */
  font-size: 20px;
  :hover {
    background-color: ${(props) => (props.border ? "transparent" : "#3a393b")};
    border: 1px solid #7620ff;
    color: ${(props) => (props.border ? "#7620ff" : "#fff")};
  }
`;
