import React from "react";
import styled from "styled-components";
// Components
import FullButton3 from "../Buttons/FullButton3";
import FullButton4 from "../Buttons/FullButton4";
// Assets
import AddImage1 from "../../assets/img/Screenshot 2024-11-11 at 3.13.01 PM.png";
import AddImage4 from "../../assets/svg/pic2.png";
import "../../style/index.css";

export default function Services() {
  return (
    <Wrapper id="services">
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h2 className="font40 extraBold">ABOUT US</h2>
                <h4 className="font15 semiBold">A few words about company</h4>
                <p className="font12">
                  We convey your brand's story to the world, creating a
                  meaningful impact and establishing a connection with your
                  audience through the art of visual storytelling.
                </p>
                <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                  <div style={{ width: "190px" }}>
                    <FullButton3 title="Get Started" action={() => alert("clicked")} />
                  </div>
                  <div style={{ width: "190px", marginLeft: "15px" }}>
                    <FullButton4 title="Contact Us" action={() => alert("clicked")} border />
                  </div>
                </ButtonsRow>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="wrapperrs">
                    <AddImgWrapp1>
                      <img src={AddImage1} alt="office" />
                    </AddImgWrapp1>
                    <AddImgWrapp4>
                      <img src={AddImage4} alt="office" />
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: auto;
  animation: fadeInUp 1.5s ease-out;  /* Add fade and slide-up animation */
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  display: flex;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;

const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;

const AddLeft = styled.div`
  width: 50%;
  p {
    font-size: 16px; /* Increased font size */
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 1; /* Ensures 'ABOUT US' comes first */
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;

const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 2; /* Ensures images come second */
    top: -40px;
  }
`;

const AddRightInner = styled.div`
  width: 100%;
`;

const AddImgWrapp1 = styled.div`
  padding-top: 7rem;
  padding-bottom: 10rem;
  padding-right: 3rem;
  width: 88%;
  margin: 0 6% 10px 6%;
  position: relative;  // Added to make this the reference point for absolute positioning
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;

const AddImgWrapp4 = styled.div`
  width: 70%;
  margin: 10% 5% auto;
  position: absolute;  // Added to enable absolute positioning within AddImgWrapp1
  bottom: 0rem;  // Positioned at the bottom
  right: 0;  // Positioned at the right
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
